import React, { useState } from 'react';
import PropTypes from 'utils/propTypes';

import classNames from 'classnames';

import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';

// import userImage from 'assets/img/users/100_4.jpg';

const Avatar = ({
  rounded,
  circle,
  src,
  size,
  tag: Tag,
  className,
  style,
  hasHover,
  handleClick,
  editUser,
  ...restProps
}) => {
  const [mouseOver, setMouseOver] = useState(false);
  const classes = classNames({ 'rounded-circle': circle, rounded }, className);
  const handleMouseOver = () => {
    setMouseOver(true);
  };
  const handleMouseOut = () => {
    const test = document.querySelector('.avatar-container:hover');
    if (!test) {
      setMouseOver(false);
    }
  };
  return (
    <div
      onMouseOut={handleMouseOut}
      className="avatar-container"
      style={{
        position: 'relative',
        width: (hasHover || editUser) ? '25vh' : null,
        height: (hasHover || editUser) ? '27vh' : null,
        margin: "auto"
      }}
      onClick={handleClick}
    >
      <Tag
        onMouseOut={handleMouseOut}
        onMouseOver={handleMouseOver}
        src={src}
        style={{
          // position: 'absolute',
          // left: '50%',
          // top: '50%',
          ...style,
          display: 'block',
          margin: 'auto',
          width: (hasHover || editUser) ? '100%' : size,
          height: (hasHover || editUser) ? '100%' : size,
        }}
        className={classes}
        {...restProps}
      />
      {hasHover && mouseOver && (
        <div
          style={{
            width: '100%',
            height: '100%',
            opacity: 0.5,
            backgroundColor: '#FFFFFF',
            position: 'absolute',
            top: 0,
            left: 0,
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        ></div>
      )}
      {hasHover && mouseOver && (
        <AddAPhotoIcon
          style={{
            position: 'absolute',
            fontSize: '6vh',
            marginLeft: '-3.15vh',
            marginTop: '-3vh',
            left: '50%',
            top: '50%',
            opacity: 0.7,
          }}
        />
      )}
    </div>
  );
};

Avatar.propTypes = {
  tag: PropTypes.component,
  rounded: PropTypes.bool,
  circle: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  src: PropTypes.string,
  style: PropTypes.object,
};

Avatar.defaultProps = {
  tag: 'img',
  rounded: false,
  circle: true,
  size: 40,
  // src: userImage,
  style: {},
};

export default Avatar;
