import { Content, Footer, Header, Sidebar } from 'components/Layout';
import React from 'react';
import {
  MdImportantDevices,
  // MdCardGiftcard,
  MdLoyalty,
} from 'react-icons/md';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

class MainLayout extends React.Component {
  static isSidebarOpen() {
    let mySidebar = document.querySelector('.cr-sidebar');
    if (!mySidebar) {
      document
        .querySelector('.cr-sidebar-mobile')
        .classList.contains('cr-sidebar-mobile--open');
    } else {
      mySidebar.classList.contains('cr-sidebar--open');
    }
  }

  componentWillReceiveProps({ breakpoint }) {
    if (breakpoint !== this.props.breakpoint) {
      this.checkBreakpoint(breakpoint);
    }
  }

  componentDidMount() {
    this.checkBreakpoint(this.props.breakpoint);
    // setTimeout(() => {
    //   if (!this.notificationSystem) {
    //     return;
    //   }

    //   this.notificationSystem.addNotification({
    //     title: <MdImportantDevices />,
    //     message: 'Welome to Reduction Admin!',
    //     level: 'info',
    //   });
    // }, 1500);

    // setTimeout(() => {
    //   if (!this.notificationSystem) {
    //     return;
    //   }

    //   this.notificationSystem.addNotification({
    //     title: <MdLoyalty />,
    //     message:
    //       'Reduction is carefully designed template powered by React and Bootstrap4!',
    //     level: 'info',
    //   });
    // }, 2500);
  }

  // close sidebar when
  handleContentClick = event => {
    // close sidebar if sidebar is open and screen size is less than `md`
    if (
      MainLayout.isSidebarOpen() &&
      (this.props.breakpoint === 'xs' ||
        this.props.breakpoint === 'sm' ||
        this.props.breakpoint === 'md')
    ) {
      this.openSidebar('close');
    }
  };

  checkBreakpoint(breakpoint) {
    switch (breakpoint) {
      case 'xs':
      case 'sm':
      case 'md':
        return this.openSidebar('close');

      case 'lg':
      case 'xl':
      default:
        return this.openSidebar('open');
    }
  }

  openSidebar(openOrClose) {
    let mySidebar = document.querySelector('.cr-sidebar');
    if (!mySidebar) {
      if (openOrClose === 'open') {
        return document
          .querySelector('.cr-sidebar-mobile')
          .classList.add('cr-sidebar-mobile--open');
      }
      document
        .querySelector('.cr-sidebar-mobile')
        .classList.remove('cr-sidebar-mobile--open');
    } else {
      if (openOrClose === 'open') {
        return mySidebar.classList.add('cr-sidebar--open');
      }
      mySidebar.classList.remove('cr-sidebar--open');
    }
  }

  render() {
    const { children } = this.props;
    return (
      <main className="cr-app bg-light">
        <Sidebar role={this.props.role} />
        <Content fluid onClick={this.handleContentClick}>
          <div style={{position: "relative"}}>
          <Header
            // {...this.props}
            loadLogData={this.props.loadLogData}
            notificationsData={this.props.notificationsData}
            handleNotificationClick={this.props.handleNotificationClick}
            badge={this.props.badge}
            onLogout={this.props.onLogout}
            imageUrl={this.props.imageUrl}
            name={this.props.name}
            email={this.props.email}
            toggleQR={this.props.toggleQR}
            role={this.props.role}
          />
          </div>
          {children}
          {/* <Footer/> */}
        </Content>

        <NotificationSystem
          dismissible={false}
          ref={notificationSystem =>
            (this.notificationSystem = notificationSystem)
          }
          style={NOTIFICATION_SYSTEM_STYLE}
        />
      </main>
    );
  }
}

export default MainLayout;
