import React from 'react';

import { Media } from 'reactstrap';
import { Link } from 'react-router-dom';

import Avatar from 'components/Avatar';

class Notifications extends React.Component {
  state = {
    notificationData: null,
    redirect: false,
    roomId: null,
  };

  componentDidMount = () => {
    this.setState({notificationData: this.props.notificationData})
  }

  handleNotificationClick = e => {

    // this.setState({ redirect: true, roomId: e.target.getAttribute('roomId') });
  };

  // shouldComponentUpdate(nextProps, nextState) {
  //   // if (this.state.redirect) {
  //   //   return true;
  //   // }
  //   // return false;
  // }

  render() {
    return (
      this.props.notificationsData &&
      this.props.notificationsData.length &&
      this.props.notificationsData.map(
        ({ id, avatar, message, date, roomId, title }) => {
          const dateArray = date.split('T')[0].split('-');
          const timeArray = date.split('T')[1].split(':');
          const myDate =
            dateArray[2] +
            '/' +
            dateArray[1] +
            '/' +
            dateArray[0] +
            ' - ' +
            timeArray[0] +
            ':' +
            timeArray[1];
          const to = '/chat';
          return (
            <Link
              to={{
                pathname: '/chat',
                state: {
                  roomId: roomId,
                  title: title,
                },
              }}
              style={{ textDecoration: 'none' }}
            >
              <Media
                roomId={roomId}
                onClick={this.props.handleNotificationClick}
                key={id}
                className="pb-2"
              >
                <Media roomId={roomId} left className="align-self-center pr-3">
                  <Avatar
                    roomId={roomId}
                    tag={Media}
                    object
                    src={avatar.toString()}
                    alt="Avatar"
                  />
                </Media>
                <Media
                  roomId={roomId}
                  body
                  middle
                  className="align-self-center"
                >
                  {message}
                </Media>
                <Media roomId={roomId} right className="align-self-center">
                  <small roomId={roomId} className="text-muted">
                    {myDate}
                  </small>
                </Media>
              </Media>
            </Link>
          );
        },
      )
    );
  }
}

export default Notifications;
