import React from 'react';

import { Navbar, Nav, NavItem } from 'reactstrap';

import SourceLink from 'components/SourceLink';

const Footer = () => {
  return (
    // <Navbar>
    //   <Nav navbar>
    //     <NavItem>
    //       2018 Reduction theme, source on <SourceLink>Github</SourceLink>
    //     </NavItem>
    //   </Nav>
    // </Navbar>
    <div
      style={{
        height: '30px',
        paddingLeft: '100px',
        position: 'relative',
        bottom: '0px',
      }}
    >
      {' '}
      Sindy{' '}
    </div>
  );
};

export default Footer;
