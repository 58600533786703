import SearchInput from 'components/SearchInput';
import React from 'react';
import {
  MdAccountCircle,
  MdArrowDropDownCircle,
  MdBorderAll,
  MdBrush,
  MdChromeReaderMode,
  MdGroupWork,
  MdKeyboardArrowDown,
  MdNotificationsActive,
  MdRadioButtonChecked,
  MdStar,
  MdTextFields,
  MdViewCarousel,
  MdViewDay,
  MdViewList,
  MdWidgets,
  MdDateRange,
  MdCameraAlt,
  MdQuestionAnswer,
  MdAnnouncement,
  MdCameraFront,
  MdRoom,
  MdAssignment,
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from 'utils/bemnames';

const sidebarBackground = {
  // backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const navComponents = [
  { to: '/buttons', name: 'buttons', exact: false, Icon: MdRadioButtonChecked },
  {
    to: '/button-groups',
    name: 'button groups',
    exact: false,
    Icon: MdGroupWork,
  },
  { to: '/forms', name: 'forms', exact: false, Icon: MdChromeReaderMode },
  { to: '/input-groups', name: 'input groups', exact: false, Icon: MdViewList },
  {
    to: '/dropdowns',
    name: 'dropdowns',
    exact: false,
    Icon: MdArrowDropDownCircle,
  },
  { to: '/badges', name: 'badges', exact: false, Icon: MdStar },
  { to: '/alerts', name: 'alerts', exact: false, Icon: MdNotificationsActive },
  { to: '/progress', name: 'progress', exact: false, Icon: MdBrush },
  { to: '/modals', name: 'modals', exact: false, Icon: MdViewDay },
];

const navContents = [
  { to: '/typography', name: 'typography', exact: false, Icon: MdTextFields },
  { to: '/tables', name: 'tables', exact: false, Icon: MdBorderAll },
];

const pageContents = [
  { to: '/login', name: 'login / signup', exact: false, Icon: MdAccountCircle },
  {
    to: '/login-modal',
    name: 'login modal',
    exact: false,
    Icon: MdViewCarousel,
  },
];

const signupItems = [
  {
    to: '/resident-sheet',
    name: 'Cadastro Master',
    exact: false,
    Icon: MdAccountCircle,
    role: [true, true, false, true],
  },
  {
    to: '/resident/signup',
    name: 'Cadastro de Moradores',
    exact: false,
    Icon: MdAccountCircle,
    role: [true, true, true, true],
  },
  {
    to: '/staff/signup',
    name: 'Cadastro de Funcionários',
    exact: false,
    Icon: MdAccountCircle,
    role: [true, true, false, true],
  },
];

const logItems = [
  {
    to: '/user/signup',
    name: 'Entrada de visita',
    exact: false,
    Icon: MdAccountCircle,
    role: [false, false, true, true],
  },
  {
    to: '/provider/signup',
    name: 'Entrada de prestadores',
    exact: false,
    Icon: MdAccountCircle,
    role: [false, false, true, true],
  },
];

const preNavItems = [
  {
    to: '/',
    name: 'Portaria',
    exact: true,
    Icon: MdWidgets,
    role: [false, false, true, true],
  },
];

const residentItems = [
  {
    to: '/resident/list',
    name: 'Editar',
    exact: false,
    Icon: MdWidgets,
    role: [true, true, true, true],
  },
  {
    to: '/resident/billets',
    name: 'Boletos',
    exact: false,
    Icon: MdWidgets,
    role: [true, true, false, true],
  },
];

const faceIDItems = [
  {
    to: '/face/search',
    name: 'Procura',
    exact: false,
    Icon: MdCameraFront,
    role: [true, true, true, true],
  },
  {
    to: '/face/list',
    name: 'Cadastro',
    exact: false,
    Icon: MdWidgets,
    role: [false, false, true, true],
  },
];

const navItems = [
  {
    to: '/resource',
    name: 'Recursos',
    exact: false,
    Icon: MdRoom,
    role: [true, true, true, true],
  },
  {
    to: '/info',
    name: 'Criar Informativo',
    exact: false,
    Icon: MdAnnouncement,
    role: [true, true, true, true],
  },
  {
    to: '/chat',
    name: 'Chat',
    exact: false,
    Icon: MdQuestionAnswer,
    role: [true, true, true, true],
  },
  {
    to: '/calendar',
    name: 'Calendário',
    exact: false,
    Icon: MdDateRange,
    role: [true, true, true, true],
  },
  {
    to: '/cam',
    name: 'Câmera',
    exact: false,
    Icon: MdCameraAlt,
    role: [true, true, false, true],
  },
];

let bem;
if (window.innerWidth < 767) {
  bem = bn.create('sidebar-mobile');
} else {
  bem = bn.create('sidebar');
}

class Sidebar extends React.Component {
  state = {
    isOpenComponents: false,
    isOpenContents: false,
    isOpenPages: false,
    isOpenSignup: false,
    isOpenLog: false,
    isOpenFaceID: false,
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    // const role = localStorage.getItem('role');

    let signupNavItems;
    // if (role === '0' || role === '1') {
    signupNavItems = (
      <div>
        <NavItem
          className={bem.e('nav-item')}
          onClick={this.handleClick('Signup')}
        >
          <BSNavLink
            style={{
              justifyContent: 'space-between',
            }}
            className={bem.e(' ')}
          >
            <div className="d-flex">
              <MdAssignment className={bem.e('nav-item-icon')} />
              <span
                style={{
                  color: '#ffffff',
                }}
                className="align-self-start"
              >
                CADASTRO
              </span>
            </div>
            <MdKeyboardArrowDown
              className={bem.e('nav-item-icon')}
              style={{
                padding: 0,
                transform: this.state.isOpenSignup
                  ? 'rotate(0deg)'
                  : 'rotate(-90deg)',
                transitionDuration: '0.3s',
                transitionProperty: 'transform',
              }}
            />
          </BSNavLink>
        </NavItem>
        <Collapse isOpen={this.state.isOpenSignup}>
          {signupItems.map(
            ({ to, name, exact, Icon, role }, index) =>
              role[this.props.role] && (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon
                      style={{ marginLeft: '3vw' }}
                      className={bem.e('nav-item-icon')}
                    />
                    <span
                      style={{
                        marginLeft: '0.5vw',
                        marginRight: '0',
                        color: '#ffffff',
                      }}
                      className=""
                    >
                      {name}
                    </span>
                  </BSNavLink>
                </NavItem>
              ),
          )}
        </Collapse>
      </div>
    );
    // }
    return (
      <aside className={bem.b()}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <div
            className="navbar-brand d-flex"
            style={{
              width: '100%',
              alignContent: 'center',
              height: window.innerWidth < 767 ? '185px' : '14.215vw',
            }}
          >
            {/* <SourceLink
              className="navbar-brand d-flex"
              style={{ width: '100%', height: '100%', alignContent: 'center' }}
            > */}
            <img
              src={
                process.env.REACT_APP_URL +
                'images/public/images/icons/LOGO_SINDY_NEGATIVO.png'
              }
              alt="Não foi possivel carregar a imagem"
              style={{ width: '75%', alignContent: 'center', margin: 'auto' }}
            />
            {/* </SourceLink> */}
          </div>

          {/* <h4
            style={{ color: 'white', alignContent: 'center', marginLeft: 70 }}
          >
            Sindy
          </h4> */}

          <hr
            style={{
              color: 'white',
              backgroundColor: 'white',
              marginBottom: 29,
            }}
          ></hr>

          <Nav navbar>
            <SearchInput style={{ width: '100%' }} />
          </Nav>
          <Nav vertical>
            {preNavItems.map(
              ({ to, name, exact, Icon, role }, index) =>
                role[this.props.role] && (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-uppercase"
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e('nav-item-icon')} />
                      <span
                        style={{
                          color: '#ffffff',
                        }}
                        className=""
                      >
                        {name}
                      </span>
                    </BSNavLink>
                  </NavItem>
                ),
            )}

            {(this.props.role == 2 || this.props.role == 3) && (
              <NavItem
                className={bem.e('nav-item')}
                onClick={this.handleClick('Log')}
              >
                <BSNavLink
                  className={bem.e(' ')}
                  style={{
                    justifyContent: 'space-between',
                  }}
                >
                  <div className="d-flex">
                    <MdAssignment
                      style={{
                        alignSelf: 'center',
                      }}
                      className={bem.e('nav-item-icon')}
                    />
                    <span
                      style={{
                        color: '#ffffff',
                      }}
                      className=" align-self-start"
                    >
                      REGISTRO DE ENTRADA
                    </span>
                  </div>
                  <MdKeyboardArrowDown
                    className={bem.e('nav-item-icon')}
                    style={{
                      padding: 0,
                      transform: this.state.isOpenLog
                        ? 'rotate(0deg)'
                        : 'rotate(-90deg)',
                      transitionDuration: '0.3s',
                      transitionProperty: 'transform',
                    }}
                  />
                </BSNavLink>
              </NavItem>
            )}
            {(this.props.role == 2 || this.props.role == 3) && (
              <Collapse isOpen={this.state.isOpenLog}>
                {logItems.map(({ to, name, exact, Icon }, index) => (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-uppercase"
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon
                        style={{ marginLeft: '3vw' }}
                        className={bem.e('nav-item-icon')}
                      />
                      <span
                        style={{
                          color: '#ffffff',
                          marginLeft: '0.5vw',
                          marginRight: '0',
                        }}
                        className=""
                      >
                        {name}
                      </span>
                    </BSNavLink>
                  </NavItem>
                ))}
              </Collapse>
            )}
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('FaceID')}
            >
              <BSNavLink
                style={{
                  justifyContent: 'space-between',
                }}
                className={bem.e(' ')}
              >
                <div className="d-flex">
                  <MdAssignment className={bem.e('nav-item-icon')} />
                  <span
                    style={{
                      color: '#ffffff',
                    }}
                    className="align-self-start"
                  >
                    FACE ID
                  </span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenFaceID
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenFaceID}>
              {faceIDItems.map(
                ({ to, name, exact, Icon, role }, index) =>
                  role[this.props.role] && (
                    <NavItem key={index} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        className="text-uppercase"
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        <Icon
                          style={{ marginLeft: '3vw' }}
                          className={bem.e('nav-item-icon')}
                        />
                        <span
                          style={{
                            color: '#ffffff',
                            marginLeft: '0.5vw',
                            marginRight: '0',
                          }}
                          className=""
                        >
                          {name}
                        </span>
                      </BSNavLink>
                    </NavItem>
                  ),
              )}
            </Collapse>
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Resident')}
            >
              <BSNavLink
                style={{
                  justifyContent: 'space-between',
                }}
                className={bem.e(' ')}
              >
                <div className="d-flex">
                  <MdAssignment className={bem.e('nav-item-icon')} />
                  <span
                    style={{
                      color: '#ffffff',
                    }}
                    className="align-self-start"
                  >
                    MORADORES
                  </span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenResident
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenResident}>
              {residentItems.map(
                ({ to, name, exact, Icon, role }, index) =>
                  role[this.props.role] && (
                    <NavItem key={index} className={bem.e('nav-item')}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        className="text-uppercase"
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        <Icon
                          style={{ marginLeft: '3vw' }}
                          className={bem.e('nav-item-icon')}
                        />
                        <span
                          style={{
                            color: '#ffffff',
                            marginLeft: '0.5vw',
                            marginRight: '0',
                          }}
                          className=""
                        >
                          {name}
                        </span>
                      </BSNavLink>
                    </NavItem>
                  ),
              )}
            </Collapse>
            {/* residentItems */}
            {signupNavItems}
            {navItems.map(
              ({ to, name, exact, Icon, role }, index) =>
                role[this.props.role] && (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-uppercase"
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e('nav-item-icon')} />
                      <span
                        style={{
                          color: '#ffffff',
                        }}
                      >
                        {name}
                      </span>
                    </BSNavLink>
                  </NavItem>
                ),
            )}
            {/* 
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Components')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdExtension className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">Components</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenComponents
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenComponents}>
              {navComponents.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Contents')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdSend className={bem.e('nav-item-icon')} />
                  <span className="">Contents</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenContents
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenContents}>
              {navContents.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Pages')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdPages className={bem.e('nav-item-icon')} />
                  <span className="">Pages</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenPages
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenPages}>
              {pageContents.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse> */}
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
