import socketIOClient from 'socket.io-client';

let socket;

export const init = token => {
  // socket = socketIOClient("https://189.111.188.10:3380/");
  socket = socketIOClient(process.env.REACT_APP_URL);

  socket.on('connect', async () => {
    if (token != null) { 
      socket.emit('joinWeb', { token: 'Bearer ' + token });
    }
  });

  socket.on('newMessage', data => {
  });

  return socket;
};

export const getSocket = token => {
  if (socket) {
    return socket;
  }
  return init(token);
};
