// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fonts/gotham/Gotham Rounded Bold.woff");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./fonts/gotham/Gotham Rounded Light.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./fonts/gotham/Gotham Rounded Medium.woff");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./fonts/avenir/AvenirLTStd-Book.otf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./fonts/avenir/AvenirLTStd-Light.otf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./fonts/avenir/AvenirLTStd-Roman.otf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
exports.push([module.id, "@font-face {\n  font-family: Gotham-Bold;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n@font-face {\n  font-family: Gotham-Light;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n\n@font-face {\n  font-family: Gotham-Medium;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n\n@font-face {\n  font-family: Avenir-Book;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n\n@font-face {\n  font-family: Avenir-Light;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n}\n\n@font-face {\n  font-family: Avenir-Roman;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");\n}\n\nbody {\n  margin: 0;\n  font-family: Gotham-Bold, Gotham-Light, Gotham-Medium, Avenir-Book,\n    Avenir-Light, Avenir-Roman, -apple-system, BlinkMacSystemFont, 'Segoe UI',\n    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',\n    'Helvetica Neue', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.scrollable {\n  position: relative;\n  overflow-y: scroll;\n  -webkit-overflow-scrolling: touch;\n}\n\n.content {\n  background: white;\n  grid-row-start: 1;\n  grid-row-end: span 3;\n}\n", ""]);
// Exports
module.exports = exports;
