import PropTypes from 'prop-types';
import React from 'react';
import { Spinner } from 'reactstrap';

const PageSpinner = ({ color = 'purple1', ...props }) => {
  console.log(props);
  return (
    <div
      className="cr-page-spinner"
      style={props.style ? props.style : { height: '100%' }}
    >
      <Spinner color={color} />
    </div>
  );
};

PageSpinner.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
    'purple1',
    'purple2',
    'purple3',
    'purple4',
    'purple5',
    'green1',
    'green2',
    'green3',
    'green4',
    'green5',
  ]),
};

export default PageSpinner;
