import React from 'react';
import { MdSearch } from 'react-icons/md';
import { Form, Input } from 'reactstrap';

class SearchInput extends React.Component {
  render() {
    return (
      <Form
        inline
        className="cr-search-form"
        onSubmit={e => e.preventDefault()}
        style={{
          width: '100%',
        }}
      >
        <MdSearch
          size="20"
          className="cr-search-form__icon-search text-dark-blue"
        />
        <Input
          type="search"
          className="cr-search-form__input"
          placeholder="Procurar..."
          onChange={this.props.onChange}
          value={this.props.value}
          style={{ width: '100%' }}
        />
      </Form>
    );
  }
}

export default SearchInput;
