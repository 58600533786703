import Avatar from 'components/Avatar';
import { UserCard } from 'components/Card';
import Notifications from 'components/Notifications';
import SearchInput from 'components/SearchInput';
import withBadge from 'hocs/withBadge';
import React from 'react';
import {
  MdClearAll,
  MdExitToApp,
  MdHelp,
  MdInsertChart,
  MdMessage,
  MdNotificationsActive,
  MdNotificationsNone,
  MdPersonPin,
  MdSettingsApplications,
} from 'react-icons/md';
import {
  Button,
  Col,
  Label,
  ListGroup,
  ListGroupItem,
  // NavbarToggler,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import bn from 'utils/bemnames';
import { getSocket } from 'utils/socket';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import jsQR from 'jsqr';

// const fs = require('browserify-fs');
const PNG = require('pngjs').PNG;

const bem = bn.create('header');

let badgeNumber;

let history;

let MdNotificationsActiveWithBadge = withBadge({
  size: 'md',
  color: 'purple1',
  style: {
    top: -10,
    right: -10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  children: <small>{badgeNumber}</small>,
})(MdNotificationsActive);

// const getHistory = () => {
//   let history = useHistory();
//   return history;
// };

class Header extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
    notificationsData: [],
    badge: 0,
    socket: null,
    token: null,
    roomId: null,
    qrReader: false,
    qrToken: null,
    videoInputs: [],
    selectedCam: 0,
    videoStream: null,
    hasData: false,
    video: null,
    videoSource: null,
    dataModalBody: null,
    qrType: null,
    read: true,
    selectedInput: null,
    userId: null,
    personalQR: false,
    auths: [],
    user: null,
    isResident: false,
    isMobile: false,
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }
  componentDidMount() {
    let isMobile = false;
    if (
      typeof window.orientation !== 'undefined' ||
      navigator.userAgent.indexOf('IEMobile') !== -1
    ) {
      isMobile = true;
    }
    // const history = getHistory();
    document.addEventListener('click', this.handleClickOutside, true);
    if (this.props.role == 2 || this.props.role == 3) {
      const video = document.querySelector('#video-min');
      if (navigator.mediaDevices) {
        navigator.mediaDevices
          .enumerateDevices()
          .then(result => {
            return result.filter(device => {
              return device.kind === 'videoinput';
            });
          })
          .then(devices => {
            if (devices.length === 0) {
              alert(
                'Você não tem dispositivos de mídia conectados para ler QR Codes',
              );
            } else {
              navigator.mediaDevices
                .getUserMedia({
                  video: {
                    deviceId: devices[devices.length - 1].deviceId,
                  },
                })
                .then(videoStream => {
                  let badge = this.props.badge;
                  if (!badge) {
                    badge = 0;
                  }
                  if (this.props.badge == 0) {
                  }
                  if (video) {
                    video.srcObject = videoStream;
                  }
                  setInterval(() => {
                    const myFrame = this.getFrame();
                    if (myFrame) {
                      const code = jsQR(
                        myFrame.data,
                        myFrame.width,
                        myFrame.height,
                      );
                      if (code) {
                        this.handleScan(code);
                      }
                    }
                  }, 200);

                  const videoInputs = devices.map((device, index) => {
                    return {
                      label: device.label.split('(')[0],
                      id: index,
                      deviceId: device.deviceId,
                    };
                  });
                  const token = localStorage.getItem('token');
                  this.setState({
                    notificationsData: this.props.notificationsData,
                    badge: badge,
                    token: 'Bearer ' + token,
                    videoInputs: videoInputs,
                    selectedInput: videoInputs[videoInputs.length - 1],
                    videoStream: videoStream,
                    video: video,
                    isMobile: isMobile,
                  });
                  MdNotificationsActiveWithBadge = withBadge({
                    size: 'md',
                    color: 'purple1',
                    style: {
                      top: -10,
                      right: -10,
                      display: 'inline-flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    },
                    children: <small>{badge}</small>,
                  })(MdNotificationsActive);
                });
            }
          })
          .catch(err => {
            console.log(err);
            alert(
              'Você deve habilitar o acesso a câmera do seu computador para poder ler QR Codes',
            );
          });
      } else {
        alert(
          'Você não tem dispositivos de mídia conectados para ler QR Codes',
        );
      }
    }
    this.setListeners();
  }

  getFrame = () => {
    if (
      this.state.read &&
      this.state.video &&
      this.state.video.videoWidth > 0 &&
      this.state.video.videoHeight > 0
    ) {
      const canvas = document.createElement('canvas');
      canvas.width = this.state.video.videoWidth;
      canvas.height = this.state.video.videoHeight;
      canvas.getContext('2d').drawImage(this.state.video, 0, 0);
      // const data = canvas
      //   .getContext('2d')
      //   .createImageData(video.videoWidth, video.videoHeight);
      // const data2 = canvas.toDataURL('image/png');
      const data = canvas
        .getContext('2d')
        .getImageData(
          0,
          0,
          this.state.video.videoWidth,
          this.state.video.videoHeight,
        );
      return data;
    } else {
      return null;
    }
  };

  setListeners = () => {
    const socket = getSocket(this.props.token);
    this.setState({ socket: socket });

    socket.on('newMessage', async data => {
      const badge = this.state.badge + 1;
      let myNotificationData;
      const notifications = this.state.notificationsData.filter(
        notificationData => {
          if (notificationData.roomId === data.message.roomId) {
            myNotificationData = notificationData;
          }
          return notificationData.roomId !== data.message.roomId;
        },
      );

      if (
        window.location.pathname != '/chat' &&
        !this.state.isOpenNotificationPopover
      ) {
        MdNotificationsActiveWithBadge = await withBadge({
          size: 'md',
          color: 'purple1',
          style: {
            top: -10,
            right: -10,
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          children: <small>{badge}</small>,
        })(MdNotificationsActive);
      }
      const avatar = data.message.avatar;
      if (
        window.location.pathname === '/chat' &&
        this.state.roomId &&
        this.state.roomId === data.message.roomId
      ) {
        notifications.unshift({
          avatar: avatar,
          date: data.message.createdAt,
          id: data.message._id,
          message: data.message.text,
          roomId: data.message.roomId,
          badge: 0,
          unread: 0,
        });
        this.setState({
          notificationsData: notifications,
          badge: 0,
        });
      } else if (window.location.pathname === '/chat') {
        notifications.unshift({
          avatar: avatar,
          date: data.message.createdAt,
          id: data.message._id,
          message: data.message.text,
          roomId: data.message.roomId,
          badge: 0,
          unread: 0,
        });
        this.setState({
          notificationsData: notifications,
          badge: 0,
        });
      } else if (myNotificationData) {
        notifications.unshift({
          avatar: avatar,
          date: data.message.createdAt,
          id: data.message._id,
          message: data.message.text,
          roomId: data.message.roomId,
          badge: myNotificationData.badge + 1,
          unread: myNotificationData.unread + 1,
        });
        this.setState({
          notificationsData: notifications,
          badge: badge,
        });
      } else {
        notifications.unshift({
          avatar: avatar,
          date: data.message.createdAt,
          id: data.message._id,
          message: data.message.text,
          roomId: data.message.roomId,
          badge: 1,
          unread: 1,
        });
        this.setState({
          notificationsData: notifications,
          badge: badge,
        });
      }
    });
    socket.on('joinedRoom', data => {
      this.setState({ roomId: data.roomId, badge: 0 });
    });
  };

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
      badge: 0,
    });

    this.state.socket.emit('sawMsgsWeb', { token: this.state.token });
    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleClickOutside = e => {
    const popover = document.querySelector('#popover');
    const popover2 = document.querySelector('#Popover2');
    if (
      popover &&
      !popover.contains(e.target) &&
      popover2 &&
      !popover2.contains(e.target)
    ) {
      this.setState({
        isOpenUserCardPopover: false,
      });
    }
  };

  togglePersonalQR = modalType => () => {
    if (!modalType) {
      return this.setState({
        personalQR: !this.state.personalQR,
        read: !this.state.read,
        qrData: null,
        hasData: false,
      });
    }

    this.setState({
      [`modal_${modalType}`]: !this.state[`modal_${modalType}`],
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();
    // if (window.innerWidth >= 767) {
    let mySidebar = document.querySelector('.cr-sidebar');
    if (!mySidebar) {
      document
        .querySelector('.cr-sidebar-mobile')
        .classList.toggle('cr-sidebar-mobile--open');
    } else {
      mySidebar.classList.toggle('cr-sidebar--open');
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state != nextState) {
      return true;
    }
    if (this.props != nextProps) {
      return true;
    }
  }

  handleNotificationClick = e => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });
  };

  handleFeed = () => {
    this.props.location.history.push('/feedback');
  };

  handleLogout = () => {
    console.log(this.props);
    // this.props.location.history.push('/');
    this.props.onLogout();
  };

  handleClickQR = e => {
    this.setState({ qrReader: true });
  };

  clickToggleQR = () => {
    this.setState({
      qrReader: false,
      qrData: null,
      hasData: false,
      read: true,
      isOpenNotificationPopover: false,
      qrToken: null,
      dataModalBody: null,
      qrType: null,
      userId: null,
      personalQR: false,
      auths: [],
      user: null,
      isResident: false,
    });
  };

  residentLog = () => {
    fetch(process.env.REACT_APP_URL + 'admin/resident/log/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: this.state.token,
      },
      body: JSON.stringify({ qrUserId: this.state.userId }),
    })
      .then(async res => {
        if (res.status === 422) {
          throw new Error('Validation failed.');
        }
        if (res.status !== 200 && res.status !== 201) {
          console.log('Error!');
          const response = await res.json();
          throw new Error(response.message);
        }
        return res.json();
      })
      .then(resData => {
        this.setState({
          qrReader: false,
          qrData: null,
          hasData: false,
          read: true,
          isOpenNotificationPopover: false,
          qrToken: null,
          dataModalBody: null,
          qrType: null,
          userId: null,
          personalQR: false,
          auths: [],
          user: null,
          isResident: false,
        });
        this.props.loadLogData();
      })
      .catch(err => {
        console.log(err);
      });
  };

  guestAuth = () => {
    this.setState({
      qrReader: false,
      qrData: null,
      hasData: false,
      read: true,
      isOpenNotificationPopover: false,
      qrToken: null,
      dataModalBody: null,
      qrType: null,
      userId: null,
      personalQR: false,
      auths: [],
      user: null,
      isResident: false,
    });
  };

  toggleQR = modalType => () => {
    if (!modalType) {
      if (this.state.qrReader) {
        return this.setState({
          qrReader: !this.state.qrReader,
          qrData: null,
          hasData: false,
          read: true,
        });
      } else {
        return this.setState({
          qrReader: !this.state.qrReader,
        });
      }
    }

    this.setState({
      [`modal_${modalType}`]: !this.state[`modal_${modalType}`],
    });
  };

  handleScan = async e => {
    if (e) {
      this.setState({ read: false });
      try {
        const data = JSON.parse(e.data);
        let qrType;
        let type;
        let pathname;
        let auxRedirect;

        if (data) {
          type = parseInt(data.type);
        }
        if (type === 0) {
          qrType = 'QR pessoal : ';
          fetch(
            process.env.REACT_APP_URL +
              'admin/user/qr-code/auths/' +
              data.token,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: this.state.token,
              },
            },
          )
            .then(async res => {
              if (res.status === 422) {
                throw new Error('Validation failed.');
              }
              if (res.status !== 200 && res.status !== 201) {
                console.log('Error!');
                const response = await res.json();
                throw new Error(response.message);
              }
              return res.json();
            })
            .then(resData => {
              if (resData.message === 'Morador do condomínio') {
                qrType += resData.message;
                auxRedirect = (
                  <Row style={{ margin: '0px', width: '100%' }}>
                    <Col>
                      <Button
                        style={{ width: '100%' }}
                        color="danger"
                        onClick={this.clickToggleQR}
                      >
                        Reportar erro
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        style={{ width: '100%' }}
                        color="green1"
                        onClick={this.residentLog}
                      >
                        Registrar Entrada
                      </Button>
                    </Col>
                  </Row>
                );
                this.setState({
                  // read: true,
                  qrData: data,
                  type: e.type,
                  qrReader: true,
                  hasData: true,
                  dataModalBody: auxRedirect,
                  qrType: qrType,
                  userId: resData.user._id,
                  user: resData.user,
                });
              } else {
                qrType += resData.message;
                auxRedirect = (
                  <Row style={{ margin: '0px', width: '100%' }}>
                    <Col>
                      <Button
                        style={{ width: '100%' }}
                        color="danger"
                        onClick={this.clickToggleQR}
                      >
                        Reportar erro
                      </Button>
                    </Col>
                    <Col>
                      <Link
                        to={{
                          pathname: '/user/signup/guest',
                          state: { guest: resData.user },
                        }}
                        style={{ width: '100%' }}
                      >
                        <Button
                          style={{ width: '100%' }}
                          color="green1"
                          onClick={this.guestAuth}
                        >
                          Registrar Visita Sem Autorização Vigente
                        </Button>
                      </Link>
                    </Col>
                    <Col>
                      <Link
                        to={{
                          pathname: '/provider/signup/new',
                          state: { provider: resData.user },
                        }}
                        style={{ width: '100%' }}
                      >
                        <Button
                          style={{ width: '100%' }}
                          color="green1"
                          onClick={this.guestAuth}
                        >
                          Registrar Prestador Sem Autorização Vigente
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                );
                if (resData.message === 'Autorizações encontradas') {
                  this.setState({
                    // read: true,
                    qrData: data,
                    type: e.type,
                    qrReader: false,
                    hasData: true,
                    auths: resData.auth,
                    user: resData.user,
                    dataModalBody: auxRedirect,
                    qrType: qrType,
                    userId: resData.user._id,
                    isResident: false,
                    personalQR: true,
                  });
                } else {
                  this.setState({
                    // read: true,
                    qrData: data,
                    type: e.type,
                    qrReader: false,
                    hasData: true,
                    auths: resData.auth,
                    user: resData.user,
                    dataModalBody: auxRedirect,
                    qrType: qrType,
                    userId: null,
                    isResident: false,
                    personalQR: true,
                  });
                }
              }
            })
            .catch(err => {
              this.setState({
                read: true,
              });
              console.log(err);
            });
        } else if (type === 1) {
          qrType = 'Visitante';
          pathname = '/user/signup/' + data.token;
          auxRedirect = (
            <Row style={{ margin: '0px', width: '100%' }}>
              <Col>
                <Button
                  style={{ width: '100%' }}
                  color="danger"
                  onClick={this.clickToggleQR}
                >
                  Aguardar
                </Button>
              </Col>
              <Col>
                <Link
                  to={{
                    pathname: pathname,
                  }}
                  style={{ width: '100%' }}
                >
                  <Button
                    style={{ width: '100%' }}
                    color="green1"
                    onClick={this.clickToggleQR}
                  >
                    Registrar Entrada
                  </Button>
                </Link>
              </Col>
            </Row>
          );
          this.setState({
            qrData: data,
            type: e.type,
            qrReader: true,
            hasData: true,
            read: true,
            dataModalBody: auxRedirect,
            qrType: qrType,
          });
        } else if (type === 2) {
          qrType = 'Prestador';
          pathname = '/provider/signup/' + data.token;
          auxRedirect = (
            <Row style={{ margin: '0px', width: '100%' }}>
              <Col>
                <Button
                  style={{ width: '100%' }}
                  color="danger"
                  onClick={this.clickToggleQR}
                >
                  Aguardar
                </Button>
              </Col>
              <Col>
                <Link
                  to={{
                    pathname: pathname,
                  }}
                  style={{ width: '100%' }}
                >
                  <Button
                    style={{ width: '100%' }}
                    color="green1"
                    onClick={this.clickToggleQR}
                  >
                    Registrar Entrada
                  </Button>
                </Link>
              </Col>
            </Row>
          );
          this.setState({
            qrData: data,
            type: e.type,
            qrReader: true,
            read: true,
            hasData: true,
            dataModalBody: auxRedirect,
            qrType: qrType,
          });
        }
      } catch (e) {
        console.log(e);
        this.setState({
          read: true,
          hasData: false,
        });
      }
    }
  };

  handleError = e => {};

  handleChangeInput = e => {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          deviceId: this.state.videoInputs[e.id].deviceId,
        },
      })
      .then(videoStream => {
        this.setState({
          selectedCam: e.id,
          selectedInput: e,
          videoStream: videoStream,
        });
      })
      .catch(err => {
        console.log(err);
        alert('Você deve habilitar o acesso a câmera do seu computador');
      });
  };

  handleCheckin = data => {
    fetch(process.env.REACT_APP_URL + 'admin/log/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: this.state.token,
      },
      body: JSON.stringify({ qrUserId: this.state.user._id, authId: data.id }),
    })
      .then(async res => {
        if (res.status === 422) {
          throw new Error('Validation failed.');
        }
        if (res.status !== 200 && res.status !== 201) {
          console.log('Error!');
          const response = await res.json();
          throw new Error(response.message);
        }
        return res.json();
      })
      .then(resData => {
        if (
          window.location.pathname === '/' &&
          !this.state.isOpenNotificationPopover
        ) {
          this.props.loadLogData();
        }
        this.setState({ personalQR: !this.state.personalQR, read: true });
      })
      .catch(err => {
        console.log(err);
      });
  };

  componentDidUpdate = () => {
    setTimeout(() => {
      const video = document.querySelector('#video-max');
      if (video) {
        video.srcObject = this.state.videoStream;
      }
      const video2 = document.querySelector('#video-min');
      if (video2 && video2.srcObject !== this.state.videoStream) {
        video2.srcObject = this.state.videoStream;
      }
      // const video
    }, 10);
  };

  render() {
    let myNotifications = [];
    if (
      this.state.notificationsData &&
      this.state.notificationsData.length > 5
    ) {
      myNotifications = this.state.notificationsData.slice(0, 5);
    } else if (this.state.notificationsData) {
      myNotifications = this.state.notificationsData;
    }

    const auxVideo = (this.props.role == 2 || this.props.role == 3) && (
      <div onClick={this.handleClickQR}>
        <video id="video-max" width="100%" autoPlay></video>
      </div>
    );

    // if (this.state.videoInputs.length > 0) {
    // const video = auxVideo.querySelector('#video-max');
    // if (video) {
    //   video.srcObject = this.state.videoStream;
    // }
    // navigator.mediaDevices
    //   .enumerateDevices()
    //   .then(result => {
    //     return result.filter(device => {
    //       return device.kind === 'videoinput';
    //     });
    //   })
    //   .then(devices => {
    //     navigator.mediaDevices
    //       .getUserMedia({
    //         video: {
    //           deviceId: devices[1].deviceId,
    //         },
    //       })
    //       .then(videoStream => {
    //     });
    // });
    // }
    let birthdayAux;
    if (this.state.user) {
      birthdayAux = this.state.user.birthday.split('T')[0].split('-');
    }
    return (
      <Navbar light expand className={bem.b('bg-white')}>
        <Nav navbar className="mr-2">
          <Button
            outline
            color="purple1"
            onClick={this.handleSidebarControlButton}
          >
            <MdClearAll size={25} />
          </Button>
        </Nav>
        {/* <Nav navbar>
          <SearchInput />
        </Nav> */}
        {!this.state.isMobile &&
          (this.props.role == 2 || this.props.role == 3) && (
            <Modal
              // size="lg"
              isOpen={this.state.qrReader}
              toggle={this.toggleQR()}
            >
              <ModalHeader style={{ width: '100%' }} toggle={this.toggleQR()}>
                <Col sm={12} xs={12} md={12} lg={12}>
                  <Row width="100%">
                    {(this.state.hasData && (
                      <Label style={{ color: 'rgb(000, 171, 152)' }}>
                        {'QRCode encontrado'}
                      </Label>
                    )) || (
                      <Label style={{ color: 'rgb(000, 171, 152)' }}>
                        {'Leitor de QRCode'}
                      </Label>
                    )}
                  </Row>
                  {!this.state.hasData && (
                    <Row width="100%">
                      <Col sm={12} xs={12} md={12} lg={12}>
                        <Label
                          style={{
                            color: 'rgb(126, 126, 125)',
                            fontWeight: 'normal',
                          }}
                        >
                          {'Selecione a Câmera: '}
                        </Label>
                      </Col>
                      <Col sm={12} xs={12} md={12} lg={12}>
                        <Select
                          style={{ width: '100%' }}
                          value={this.state.selectedInput}
                          onChange={this.handleChangeInput}
                          options={this.state.videoInputs}
                          // onBlur={this.aptBlur}
                          // placeholder="Selecionar o apartamento"
                          styles={{
                            singleValue: () => ({
                              color: 'rgb(126, 126, 125)',
                              fontWeight: 'lighter',
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              background: state.isSelected
                                ? 'rgb(000, 171, 152)'
                                : null,
                              color: state.isSelected
                                ? '#ffffff'
                                : 'rgb(126, 126, 125)',
                              fontWeight: 'lighter',
                              // marginRight: '10px',
                              // marginLeft: '10px',
                            }),
                          }}
                        />
                      </Col>
                    </Row>
                  )}
                </Col>
              </ModalHeader>
              {/* <ModalBody>{(!this.state.hasData && qrReader) || qrData}</ModalBody> */}
              <ModalBody>
                {(!this.state.hasData && auxVideo) || this.state.qrType}
                {this.state.hasData && this.state.user && (
                  <Row>
                    <Col sm={9}>
                      <h6 style={{ marginTop: '8px' }}>
                        {'Nome: ' + this.state.user.name}
                      </h6>
                      <h6>
                        {'CPF: ' +
                          this.state.user.cpf.slice(0, 9) +
                          '-' +
                          this.state.user.cpf.slice(9)}
                      </h6>
                      <h6>
                        {'RG: ' +
                          this.state.user.rg.number.slice(
                            0,
                            this.state.user.rg.number.length - 1,
                          ) +
                          '-' +
                          this.state.user.rg.number[
                            this.state.user.rg.number.length - 1
                          ]}
                      </h6>
                      <h6>
                        {'Orgão emissor: ' + this.state.user.rg.issuingBody}
                      </h6>
                      <h6>
                        {'Data de nascimento: ' +
                          birthdayAux[2] +
                          '/' +
                          birthdayAux[1] +
                          '/' +
                          birthdayAux[0]}
                      </h6>
                      <h6>
                        {'Telefone: ' +
                          this.state.user.phone.slice(0, 3) +
                          ' ' +
                          this.state.user.phone.slice(3)}
                      </h6>
                    </Col>
                    <Col sm={3}>
                      <Avatar
                        src={
                          process.env.REACT_APP_URL + this.state.user.imageUrl
                        }
                        style={{
                          width: '100%',
                          height: '100%',
                        }}
                        rounded={true}
                        circle={false}
                        editUser={true}
                      />
                    </Col>
                  </Row>
                )}
              </ModalBody>
              <ModalFooter>
                {!this.state.hasData || this.state.dataModalBody}
              </ModalFooter>
            </Modal>
          )}
        {(this.props.role == 2 || this.props.role == 3) && (
          <Modal
            size="lg"
            isOpen={this.state.personalQR}
            toggle={this.togglePersonalQR()}
          >
            <ModalHeader toggle={this.togglePersonalQR()}>
              <Col sm={12}>{'QRCode pessoal encontrado'}</Col>
            </ModalHeader>
            {/* <ModalBody>{(!this.state.hasData && qrReader) || qrData}</ModalBody> */}
            <ModalBody>
              {this.state.user && (
                <Row>
                  <Col sm={9}>
                    <h6 style={{ marginTop: '8px' }}>
                      {'Nome: ' + this.state.user.name}
                    </h6>
                    <h6>
                      {'CPF: ' +
                        this.state.user.cpf.slice(0, 9) +
                        '-' +
                        this.state.user.cpf.slice(9)}
                    </h6>
                    <h6>
                      {'RG: ' +
                        this.state.user.rg.number.slice(
                          0,
                          this.state.user.rg.number.length - 1,
                        ) +
                        '-' +
                        this.state.user.rg.number[
                          this.state.user.rg.number.length - 1
                        ]}
                    </h6>
                    <h6>
                      {'Orgão emissor: ' + this.state.user.rg.issuingBody}
                    </h6>
                    <h6>
                      {'Data de nascimento: ' +
                        birthdayAux[2] +
                        '/' +
                        birthdayAux[1] +
                        '/' +
                        birthdayAux[0]}
                    </h6>
                    <h6>
                      {'Telefone: ' +
                        this.state.user.phone.slice(0, 3) +
                        ' ' +
                        this.state.user.phone.slice(3)}
                    </h6>
                  </Col>
                  <Col sm={3}>
                    <Avatar
                      src={process.env.REACT_APP_URL + this.state.user.imageUrl}
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                      rounded={true}
                      circle={false}
                      editUser={true}
                    />
                  </Col>
                </Row>
              )}
              {(!this.state.isResident &&
                this.state.auths &&
                this.state.auths.map(data => {
                  const startArray = data.start.split('T')[0].split('-');
                  const endArray = data.end.split('T')[0].split('-');
                  const startTime = data.start.split('T')[1].split(':');
                  const endTime = data.end.split('T')[1].split(':');

                  return (
                    <Row style={{ width: '100%' }}>
                      <Col sm={12}>
                        <Row>
                          <Col sm={9}>
                            <Label>
                              {startArray[2] +
                                '/' +
                                startArray[1] +
                                '/' +
                                startArray[0] +
                                ' ' +
                                startTime[0] +
                                ':' +
                                startTime[1] +
                                ' - ' +
                                endArray[2] +
                                '/' +
                                endArray[1] +
                                '/' +
                                endArray[0] +
                                ' ' +
                                endTime[0] +
                                ':' +
                                endTime[1]}
                            </Label>
                          </Col>
                          <Col sm={3}>
                            <Button
                              onClick={() => {
                                this.handleCheckin(data);
                              }}
                            >
                              Liberar
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                      <Col></Col>
                    </Row>
                  );
                })) ||
                this.state.qrType}
            </ModalBody>
            <ModalFooter>
              {!this.state.hasData || this.state.dataModalBody}
            </ModalFooter>
          </Modal>
        )}
        {/* <Button
          style={{ marginRight: '2vh' }}
          onClick={this.props.clickToggleQR}
        >
          Leitor de QR Code
        </Button> */}
        {!this.state.isMobile &&
          (this.props.role == 2 || this.props.role == 3) && (
            <div onClick={this.handleClickQR}>
              <video
                id="video-min"
                style={{ marginTop: '1vh', height: '6vh' }}
                autoPlay
              ></video>
            </div>
          )}
        {/* {!this.state.qrReader && qrReader} */}
        <Nav navbar className={bem.e('nav-right')}>
          <NavItem className="d-inline-flex">
            <NavLink
              id="Popover1"
              color="purple1"
              className="position-relative"
            >
              {this.state.badge == 0 ? (
                <MdNotificationsNone
                  size={25}
                  className="text-purple1 can-click"
                  onClick={this.toggleNotificationPopover}
                />
              ) : (
                <MdNotificationsActiveWithBadge
                  size={25}
                  className="text-purple1 can-click animated swing infinite"
                  onClick={this.toggleNotificationPopover}
                />
              )}
            </NavLink>
            <Popover
              placement="bottom"
              isOpen={this.state.isOpenNotificationPopover}
              toggle={this.toggleNotificationPopover}
              target="Popover1"
            >
              <PopoverBody>
                <Notifications
                  notificationsData={myNotifications}
                  handleNotificationClick={this.handleNotificationClick}
                />
              </PopoverBody>
            </Popover>
          </NavItem>

          <NavItem>
            <NavLink id="Popover2">
              <Avatar
                src={process.env.REACT_APP_URL + this.props.imageUrl}
                onClick={this.toggleUserCardPopover}
                className="can-click"
              />
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={this.state.isOpenUserCardPopover}
              toggle={this.toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}
              id="popover"
            >
              <PopoverBody className="p-0 border-light">
                <UserCard
                  title={this.props.name}
                  subtitle={this.props.email}
                  avatar={process.env.REACT_APP_URL + this.props.imageUrl}
                  text="Last updated 3 mins ago"
                  className="border-light"
                >
                  <ListGroup flush>
                    {/* <ListGroupItem tag="button" action className="border-light">
                      <NavLink href="/feedback">
                        <MdMessage /> Feedback
                      </NavLink>
                    </ListGroupItem> */}
                    {/* <ListGroupItem tag="button" action className="border-light">
                      <MdInsertChart /> Stats
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdMessage /> Messages
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdSettingsApplications /> Settings
                    </ListGroupItem> */}
                    {/* <ListGroupItem tag="button" action className="border-light">
                      <Label style={{ color: 'rgb(000, 171, 152)' }}>
                        <MdHelp /> Versão
                      </Label>
                    </ListGroupItem> */}
                    <ListGroupItem
                      tag="button"
                      onClick={this.handleLogout}
                      action
                      className="border-light"
                    >
                      <NavLink href="/">
                        <Label
                          style={{ color: 'rgb(000, 171, 152)', margin: '0px' }}
                        >
                          <MdExitToApp /> Logout
                        </Label>
                      </NavLink>
                    </ListGroupItem>
                  </ListGroup>
                </UserCard>
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

export default Header;
