import GAListener from 'components/GAListener';
import { MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import React from 'react';
import componentQueries from 'react-component-queries';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';
import './App.css';
import { init, getSocket } from './utils/socket';
import GlobalFonts from './fonts/fonts';
import cheerio from 'cheerio';

// const AlertPage = React.lazy(() => import('pages/AlertPage'));
// const AuthModalPage = React.lazy(() => import('pages/AuthModalPage'));
// const BadgePage = React.lazy(() => import('pages/BadgePage'));
// const ButtonGroupPage = React.lazy(() => import('pages/ButtonGroupPage'));
// const ButtonPage = React.lazy(() => import('pages/ButtonPage'));
// const CardPage = React.lazy(() => import('pages/CardPage'));
// const ChartPage = React.lazy(() => import('pages/ChartPage'));
// const DropdownPage = React.lazy(() => import('pages/DropdownPage'));
// const FormPage = React.lazy(() => import('pages/FormPage'));
// const InputGroupPage = React.lazy(() => import('pages/InputGroupPage'));
// const ModalPage = React.lazy(() => import('pages/ModalPage'));
// const ProgressPage = React.lazy(() => import('pages/ProgressPage'));
// const TablePage = React.lazy(() => import('pages/TablePage'));
// const TypographyPage = React.lazy(() => import('pages/TypographyPage'));
// const WidgetPage = React.lazy(() => import('pages/WidgetPage'));
const PrivacyPage = React.lazy(() => import('pages/PrivacyPage'));
const LogPage = React.lazy(() => import('pages/LogPage'));
const SignupPage = React.lazy(() => import('pages/SignupPage'));
const LoginPage = React.lazy(() => import('pages/LoginPage'));
const ResourcesPage = React.lazy(() => import('pages/ResourcesPage'));
const ResourcePage = React.lazy(() => import('pages/ResourcePage'));
const AddResourcePage = React.lazy(() => import('pages/AddResourcePage'));
const SignUpResidentPage = React.lazy(() => import('pages/SignUpResidentPage'));
const AdminSignUpResidentPage = React.lazy(() =>
  import('pages/AdminSignUpResidentPage'),
);
const SignupGuestPage = React.lazy(() => import('pages/SignupGuestPage'));
const SignupGuestQRPage = React.lazy(() => import('pages/SignupGuestQRPage'));
const SignupProviderPage = React.lazy(() => import('pages/SignupProviderPage'));
const SignupProviderQRPage = React.lazy(() =>
  import('pages/SignupProviderQRPage'),
);
const SignupStaffPage = React.lazy(() => import('pages/SignupStaffPage'));
const SignupCSVPage = React.lazy(() => import('pages/SignupCSVPage'));
const SheetResultPage = React.lazy(() => import('pages/SheetResultPage'));
const ChatPage = React.lazy(() => import('pages/ChatPage'));
const ConfirmationPage = React.lazy(() => import('pages/ConfirmationPage'));
const QRCodePage = React.lazy(() => import('pages/QRCodePage'));
const InfoPage = React.lazy(() => import('pages/InfoPage'));
const FacePage = React.lazy(() => import('pages/FacePage'));
const FaceHistoryPage = React.lazy(() => import('pages/FaceHistoryPage'));
const CalendarPage = React.lazy(() => import('pages/CalendarPage'));
const CamPage = React.lazy(() => import('pages/CamPage'));
const EditResourcePage = React.lazy(() => import('pages/EditResourcePage'));
const ResidentsPage = React.lazy(() => import('pages/ResidentsPage'));
const EditResidentPage = React.lazy(() => import('pages/EditResidentPage'));
const ForgotPasswordPage = React.lazy(() => import('pages/ForgotPasswordPage'));
const ResetPasswordPage = React.lazy(() => import('pages/ResetPasswordPage'));
const BilletsPage = React.lazy(() => import('pages/BilletsPage'));
const ResidentsResultPage = React.lazy(() =>
  import('pages/ResidentsResultPage'),
);
const EditUserPage = React.lazy(() => import('pages/EditUserPage'));
const FeedBackPage = React.lazy(() => import('pages/FeedBackPage'));
const FaceRegisterPage = React.lazy(() => import('pages/FaceRegisterPage'));
const FaceListPage = React.lazy(() => import('pages/FaceListPage'));
const TestPage = React.lazy(() => import('pages/TestPage'));

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

class App extends React.Component {
  state = {
    isAuth: false,
    token: null,
    staffId: null,
    userId: null,
    badge: null,
    notificationsData: [],
    imageUrl: null,
    name: null,
    email: null,
    modal: false,
    modalText: false,
    modalStatus: false,
    modalQR: false,
    qrData: null,
    qrTitle: null,
    refreshLog: false,
    role: null,
    authLoading: true,
    loading: true,

    nScripts: null,
    flag: false,
    widget: false,
  };

  componentDidMount = async () => {
    //     var parsedHTML = cheerio.load('<html><head><script type="text/javascript" src="https://widget.freshworks.com/widgets/64000000147.js"></script></head></html>')
    // console.log( parsedHTML('script').get()[0].attribs['src'] );
    var script1 = document.createElement('script');
    script1.type = 'text/javascript';
    script1.textContent = `window.fwSettings = { widget_id: 64000000147 };
    !(function () {
      if ('function' != typeof window.FreshworksWidget) {
        var n = function () {
          n.q.push(arguments);
        };
        (n.q = []), (window.FreshworksWidget = n);
      }
    })();
    `;
    // script1.defer = true;
    document.body.appendChild(script1);
    var script2 = document.createElement('script');
    script2.type = 'text/javascript';
    script2.src = 'https://widget.freshworks.com/widgets/64000000147.js';
    script2.async = true;
    script2.defer = true;
    document.body.appendChild(script2);

    // this.setState({ authLoading: true });
    const token = localStorage.getItem('token');
    const staffId = localStorage.getItem('staffId');
    const userId = localStorage.getItem('userId');
    if (!token) {
      this.setState({ loading: false });
      return;
    }
    // await fetch('http://189.111.188.10:3380/auth/staff', {
    await fetch(process.env.REACT_APP_URL + 'auth/staff', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(async res => {
        if (res.status === 422) {
          const resAux = await res.json();

          this.setState({
            modal: true,
            modalStatus: res.status,
            modalText: resAux.message,
          });
          throw new Error('Validation failed.');
        }
        if (res.status !== 200) {
          const resAux = await res.json();
          console.log('Error!');
          this.setState({
            modal: true,
            modalStatus: res.status,
            modalText: resAux.message,
          });
          throw new Error('Could not authenticate you!');
        }
        return res.json();
      })
      .then(resData => {
        init(token);
        this.setState({
          isAuth: true,
          token: token,
          staffId: staffId,
          userId: userId,
          badge: resData.badge,
          notificationsData: resData.notificationsData,
          imageUrl: resData.imageUrl,
          name: resData.name,
          email: resData.email,
          role: resData.role,
          loading: false,
        });
      })
      .catch(err => {
        console.log('ERROR');
        console.log(err);
        this.setState({
          isAuth: false,
          token: null,
          staffId: null,
          userId: null,
          authLoading: false,
          error: err,
          loading: false,
        });
        localStorage.clear();
      });
  };

  toggle = modalType => () => {
    if (!modalType) {
      return this.setState({
        modal: !this.state.modal,
      });
    }

    this.setState({
      [`modal_${modalType}`]: !this.state[`modal_${modalType}`],
    });
  };

  componentDidUpdate() {
    if (!this.state.flag) {
      const myInterval = setInterval(() => {
        // const test = document.getElementsByClassName('frame-content');
        const buttonFrame = document.getElementById('launcher-frame');
        if (buttonFrame) {
          const button = buttonFrame.contentWindow.document.querySelector(
            '.launcher-button',
          );
          button.addEventListener('click', this.handleIFrameClick);
          // button.style.background = 'rgb(000, 171, 152)';
          // button.style.fontFamily = "Gotham-Bold"
          // test3.style.color = "green1"
          this.setState({ flag: true });
          clearInterval(myInterval);
        }
      }, 10);
    }
  }

  handleIFrameClick = () => {
    const myInterval = setInterval(() => {
      // const test = document.getElementsByClassName('frame-content');
      const widgetFrame = document.getElementById('widget-frame');
      if (widgetFrame) {
        const widgetHeader = widgetFrame.contentWindow.document.querySelector(
          '.kbfwkc',
        );
        widgetHeader.addEventListener('click', this.handleIFrameClick);
        // widgetHeader.style.background = 'rgb(000, 171, 152)';
        // widgetHeader.style.fontFamily = "Gotham-Bold"
        // test3.style.color = "green1"
        this.setState({ flag: true });
        clearInterval(myInterval);
      }
    }, 1000);
  };

  logoutHandler = async () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('staffId');
    localStorage.removeItem('role');
    const socket = await getSocket();
    socket.emit('logout', this.state.token);
    socket.disconnect();
    this.setState({ isAuth: false, token: null });
  };

  loginHandler = (event, authData) => {
    event.preventDefault();
    // this.setState({ authLoading: true });
    fetch(process.env.REACT_APP_URL + 'auth/staff/login', {
      // fetch(process.env.REACT_APP_URL + "auth/staff/login", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        dbName: authData.dbName,
        email: authData.email,
        password: authData.password,
      }),
    })
      .then(async res => {
        if (res.status === 422) {
          const resAux = await res.json();
          this.setState({
            modal: true,
            modalStatus: res.status,
            modalText: resAux,
          });
          throw new Error('Validation failed.');
        }
        if (res.status !== 200 && res.status !== 201) {
          const resAux = await res.json();
          this.setState({
            modal: true,
            modalStatus: res.status,
            modalText: resAux.message,
          });
          throw new Error('Could not authenticate you!');
        }
        return res.json();
      })
      .then(async resData => {
        // await fetch(process.env.REACT_APP_URL + "auth/staff", {
        await fetch(process.env.REACT_APP_URL + 'auth/staff', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + resData.token,
          },
        })
          .then(res => {
            if (res.status === 422) {
              throw new Error('Validation failed.');
            }
            if (res.status !== 200) {
              console.log('Error!');
              throw new Error('Could not authenticate you!');
            }
            return res.json();
          })
          .then(responseData => {
            init(resData.token);
            this.setState({
              isAuth: true,
              token: resData.token,
              staffId: resData.staffId,
              userId: resData.userId,
              badge: responseData.badge,
              notificationsData: responseData.notificationsData,
              imageUrl: resData.imageUrl,
              name: resData.name,
              email: resData.email,
              role: resData.role,
            });
            localStorage.setItem('token', resData.token);
            localStorage.setItem('staffId', resData.staffId);
            localStorage.setItem('userId', resData.userId);
            localStorage.setItem('role', resData.role);
          })
          .catch(err => {
            console.log('ERROR');
            console.log(err);
            this.setState({
              isAuth: false,
              token: null,
              staffId: null,
              userId: null,
              authLoading: false,
              error: err,
            });
            localStorage.clear();
          });
        // this.setState({
        //   isAuth: true,
        //   token: resData.token,
        //   authLoading: false,
        //   staffId: resData.staffId,
        //   userId: resData.userId,
        //   notificationsData: resData.notificationsData,
        //   badge: resData.badge,
        // });
        // init(resData.token);
        // localStorage.setItem('token', resData.token);
        // localStorage.setItem('staffId', resData.staffId);
        // localStorage.setItem('userId', resData.userId);
        // this.getResources(resData.token);
        // this.getResources(resData.token);
      })
      .catch(err => {
        console.log(err);
        this.setState({
          // isAuth: false,
          authLoading: false,
          error: err,
        });
      });
  };

  handleNotificationClick = e => {};

  loadLogData = () => {
    this.setState({ refreshLog: !this.state.refreshLog });
  };

  render() {
    if (this.state.loading) {
      return (
        <div style={{ height: '100vh' }}>
          <PageSpinner></PageSpinner>
        </div>
      );
    }
    let routes;
    let path = '/';
    if (this.state.role == 1 || this.state.role == 2) {
      path = '/resident/billets';
    }
    if (this.state.isAuth) {
      routes = (
        <Switch>
          <MainLayout
            // {...props}
            loadLogData={this.loadLogData.bind(this)}
            notificationsData={this.state.notificationsData}
            badge={this.state.badge}
            breakpoint={this.props.breakpoint}
            onLogout={this.logoutHandler.bind(this)}
            handleNotificationClick={this.handleNotificationClick}
            imageUrl={this.state.imageUrl}
            name={this.state.name}
            email={this.state.email}
            role={this.state.role}
            // toggleQR={this.toggleQR.bind(this)}
          >
            <React.Suspense fallback={<PageSpinner />}>
              {/* <Route exact path="/login-modal" component={AuthModalPage} /> */}
              {/* <Route exact path="/buttons" component={ButtonPage} /> */}
              {/* <Route exact path="/cards" component={CardPage} /> */}
              {/* <Route exact path="/widgets" component={WidgetPage} /> */}
              {/* <Route exact path="/typography" component={TypographyPage} /> */}
              {/* <Route exact path="/alerts" component={AlertPage} /> */}
              {/* <Route exact path="/tables" component={TablePage} /> */}
              {/* <Route exact path="/badges" component={BadgePage} /> */}
              {/* <Route exact path="/button-groups" component={ButtonGroupPage} /> */}
              {/* <Route exact path="/dropdowns" component={DropdownPage} /> */}
              {/* <Route exact path="/progress" component={ProgressPage} /> */}
              {/* <Route exact path="/modals" component={ModalPage} /> */}
              {/* <Route exact path="/forms" component={FormPage} /> */}
              {/* <Route exact path="/input-groups" component={InputGroupPage} /> */}
              {/* <Route exact path="/charts" component={ChartPage} /> */}
              {/* <Route
                exact
                path="/signup"
                render={() => (
                  <SignupPage token={this.state.token}></SignupPage>
                )}
              /> */}
              {(this.state.role == 0 ||
                this.state.role == 1 ||
                this.state.role == 3) && (
                <Route
                  exact
                  path="/resident/billets"
                  render={props => (
                    <BilletsPage
                      {...props}
                      token={this.state.token}
                    ></BilletsPage>
                  )}
                />
              )}
              {(this.state.role == 0 || this.state.role == 1) &&
                window.location.pathname === '/' && (
                  <Redirect to="/resident/billets" />
                )}
              {(this.state.role == 0 ||
                this.state.role == 1 ||
                this.state.role == 3) && (
                <Route
                  exact
                  path="/resource-form"
                  render={props => (
                    <AddResourcePage
                      {...props}
                      token={this.state.token}
                    ></AddResourcePage>
                  )}
                />
              )}
              {(this.state.role == 0 ||
                this.state.role == 1 ||
                this.state.role == 3) && (
                <Route
                  exact
                  path="/resource-edit/:resourceId"
                  render={props => (
                    <EditResourcePage
                      {...props}
                      token={this.state.token}
                    ></EditResourcePage>
                  )}
                />
              )}
              <Route
                exact
                path="/resource/:resourceId"
                render={props => (
                  <ResourcePage
                    {...props}
                    token={this.state.token}
                    role={this.state.role}
                  ></ResourcePage>
                )}
              />
              <Route
                exact
                path="/resource"
                render={props => (
                  <ResourcesPage
                    {...props}
                    role={this.state.role}
                    token={this.state.token}
                  ></ResourcesPage>
                )}
              />
              <Route
                exact
                path="/chat"
                render={props => (
                  <ChatPage
                    {...props}
                    style={{ height: '60vh' }}
                    token={this.state.token}
                  ></ChatPage>
                )}
              />
              <Route
                exact
                path="/info"
                render={props => (
                  <InfoPage {...props} token={this.state.token}></InfoPage>
                )}
              />
              <Route
                exact
                path="/feedback"
                render={props => (
                  <FeedBackPage
                    {...props}
                    token={this.state.token}
                  ></FeedBackPage>
                )}
              />
              <Route
                exact
                path="/calendar"
                render={props => (
                  <CalendarPage
                    {...props}
                    token={this.state.token}
                  ></CalendarPage>
                )}
              />
              {(this.state.role == 0 ||
                this.state.role == 1 ||
                this.state.role == 3) && (
                <Route
                  exact
                  path="/cam"
                  render={props => (
                    <CamPage {...props} token={this.state.token}></CamPage>
                  )}
                />
              )}
              <Route
                exact
                path="/auth/signup/:token"
                render={props => (
                  <SignUpResidentPage
                    {...props}
                    token={this.state.token}
                  ></SignUpResidentPage>
                )}
              />
              <Route
                exact
                path="/resident/signup"
                render={props => (
                  <AdminSignUpResidentPage
                    {...props}
                    token={this.state.token}
                  ></AdminSignUpResidentPage>
                )}
              />
              {(this.state.role == 0 ||
                this.state.role == 1 ||
                this.state.role == 3) && (
                <Route
                  exact
                  path="/staff/signup"
                  render={props => (
                    <SignupStaffPage
                      {...props}
                      token={this.state.token}
                    ></SignupStaffPage>
                  )}
                />
              )}
              {(this.state.role == 2 || this.state.role == 3) && (
                <Route
                  exact
                  path="/user/signup"
                  render={props => (
                    <SignupGuestPage
                      {...props}
                      token={this.state.token}
                    ></SignupGuestPage>
                  )}
                />
              )}
              {(this.state.role == 2 || this.state.role == 3) && (
                <Route
                  exact
                  path="/user/signup/:token"
                  render={props => (
                    <SignupGuestQRPage
                      {...props}
                      token={this.state.token}
                    ></SignupGuestQRPage>
                  )}
                />
              )}
              {(this.state.role == 2 || this.state.role == 3) && (
                <Route
                  exact
                  path="/provider/signup"
                  render={props => (
                    <SignupProviderPage
                      {...props}
                      token={this.state.token}
                    ></SignupProviderPage>
                  )}
                />
              )}
              {(this.state.role == 2 || this.state.role == 3) && (
                <Route
                  exact
                  path="/provider/signup/:token"
                  render={props => (
                    <SignupProviderQRPage
                      {...props}
                      token={this.state.token}
                    ></SignupProviderQRPage>
                  )}
                />
              )}
              <Route
                exact
                path="/confirmation/:invitationToken"
                render={props => (
                  <ConfirmationPage
                    {...props}
                    token={this.state.token}
                  ></ConfirmationPage>
                )}
              />
              <Route
                exact
                path="/qr-code"
                render={props => (
                  <QRCodePage {...props} token={this.state.token}></QRCodePage>
                )}
              />
              {(this.state.role == 0 ||
                this.state.role == 1 ||
                this.state.role == 3) && (
                <Route
                  exact
                  path="/resident-sheet"
                  render={props => (
                    <SignupCSVPage
                      {...props}
                      token={this.state.token}
                    ></SignupCSVPage>
                  )}
                />
              )}
              {(this.state.role == 0 ||
                this.state.role == 1 ||
                this.state.role == 3) && (
                <Route
                  exact
                  path="/signup-csv-result"
                  render={props => (
                    <SheetResultPage
                      {...props}
                      token={this.state.token}
                    ></SheetResultPage>
                  )}
                />
              )}
              <Route
                exact
                path="/face/history/:id"
                render={props => (
                  <FaceHistoryPage
                    {...props}
                    token={this.state.token}
                  ></FaceHistoryPage>
                )}
              />
              <Route
                exact
                path="/face/search"
                render={props => (
                  <FacePage {...props} token={this.state.token}></FacePage>
                )}
              />
              <Route
                exact
                path="/face/signup"
                render={props => (
                  <FaceRegisterPage
                    {...props}
                    token={this.state.token}
                  ></FaceRegisterPage>
                )}
              />
              <Route
                exact
                path="/face/list"
                render={props => (
                  <FaceListPage
                    {...props}
                    token={this.state.token}
                  ></FaceListPage>
                )}
              />
              <Route
                exact
                path="/residents/edit"
                render={props => (
                  <EditResidentPage
                    {...props}
                    role={this.props.role}
                    token={this.state.token}
                  ></EditResidentPage>
                )}
              />
              <Route
                exact
                path="/user/edit"
                render={props => (
                  <EditUserPage
                    {...props}
                    role={this.props.role}
                    token={this.state.token}
                  ></EditUserPage>
                )}
              />
              {(this.state.role == 0 ||
                this.state.role == 1 ||
                this.state.role == 3) && (
                <Route
                  exact
                  path="/residents/result"
                  render={props => (
                    <ResidentsResultPage
                      {...props}
                      token={this.state.token}
                    ></ResidentsResultPage>
                  )}
                />
              )}
              <Route
                exact
                path="/resident/list"
                render={props => (
                  <ResidentsPage
                    {...props}
                    token={this.state.token}
                  ></ResidentsPage>
                )}
              />
              <Route
                exact
                path="/test"
                render={props => (
                  <TestPage
                    {...props}
                    refresh={this.state.refreshLog}
                    token={this.state.token}
                  ></TestPage>
                )}
              />
              {(this.state.role == 2 || this.state.role == 3) && (
                <Route
                  exact
                  path="/"
                  render={props => (
                    <LogPage
                      {...props}
                      refresh={this.state.refreshLog}
                      token={this.state.token}
                    ></LogPage>
                  )}
                />
              )}
              {/* {this.state.role == 2 && (
                <Route render={() => <Redirect to={{ pathname: '/' }} />} />
              )}
              {(this.state.role == 0 || this.state.role == 1) && (
                <Route
                  render={() => (
                    <Redirect to={{ pathname: '/resident/billets' }} />
                  )}
                />
              )} */}
            </React.Suspense>
          </MainLayout>
          <Redirect to={{ pathname: path }} />
        </Switch>
      );
    } else {
      routes = (
        <Switch>
          <React.Suspense fallback={<PageSpinner />}>
            <Route
              exact
              path="/confirmation/:invitationToken"
              render={props => (
                <ConfirmationPage
                  {...props}
                  token={this.state.token}
                ></ConfirmationPage>
              )}
            />
            <Route
              exact
              path="/forgot-password"
              render={() => (
                <ForgotPasswordPage
                // {...props}
                // token={this.state.token}
                ></ForgotPasswordPage>
              )}
            />
            <Route
              exact
              path="/qr-code"
              render={props => (
                <QRCodePage {...props} token={this.state.token}></QRCodePage>
              )}
            />
            <Route
              exact
              path="/auth/signup/:token"
              render={props => (
                <SignUpResidentPage
                  {...props}
                  token={this.state.token}
                ></SignUpResidentPage>
              )}
            />
            <Route
              exact
              path="/password/:userToken"
              render={props => (
                <ResetPasswordPage
                  {...props}
                  token={this.state.token}
                ></ResetPasswordPage>
              )}
            />
            {/* <Route
              exact
              path="/test2"
              render={props => (
                <Test2Page {...props} token={this.state.token}></Test2Page>
              )}
            /> */}
            <Route
              exact
              path="/test"
              render={props => (
                <TestPage {...props} token={this.state.token}></TestPage>
              )}
            />

              <Route
              exact
              path="/privacy"
              render={props => (
                <PrivacyPage></PrivacyPage>
              )}
            />
            <Route
              exact
              path="/"
              render={() => (
                <div>
                  <Modal isOpen={this.state.modal} toggle={this.toggle()}>
                    <ModalHeader toggle={this.toggle()}>
                      {'Error Status ' + this.state.modalStatus}
                    </ModalHeader>
                    <ModalBody>
                      {(this.state.modalStatus !== 422 &&
                        this.state.modalText) ||
                        (this.state.modalText.data &&
                          'Entre com um email válido')}
                    </ModalBody>
                    <ModalFooter>
                      <Button color="green1" onClick={this.toggle()}>
                        OK
                      </Button>
                    </ModalFooter>
                  </Modal>
                  <LoginPage onLogin={this.loginHandler}></LoginPage>
                </div>
              )}
            />
          </React.Suspense>
          <Redirect to="/" />
        </Switch>
      );
    }
    return (
      <BrowserRouter basename={getBasename()}>
        <GlobalFonts />
        <GAListener>{routes}</GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
