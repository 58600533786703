import { createGlobalStyle } from 'styled-components';

// import NameOfYourFontWoff from './nameOfYourFont.woff';
// import NameOfYourFontWoff2 from './nameOfYourFont.woff2';

export default createGlobalStyle`
  @font-face {
    font-family: Gotham-Bold;
    src: url('./fonts/gotham/Gotham\ Rounded\ Bold.woff');
  }
  
  @font-face {
    font-family: Gotham-Light;
    src: url('./fonts/gotham/Gotham\ Rounded\ Light.woff');
  }
  
  @font-face {
    font-family: Gotham-Medium;
    src: url('./fonts/gotham/Gotham\ Rounded\ Medium.woff');
  }
  
  @font-face {
    font-family: Avenir-Book;
    src: url('./fonts/avenir/AvenirLTStd-Book.otf');
  }
  
  @font-face {
    font-family: Avenir-Light;
    src: url('./fonts/avenir/AvenirLTStd-Light.otf');
  }
  
  @font-face {
    font-family: Avenir-Roman;
    src: url('./fonts/avenir/AvenirLTStd-Roman.otf');
  }
`;
